<template lang="pug">
include ../../../configs/template
div.row.text-left
  div(v-if="statement?.status_dkk?.exists_docs.length").col-12.mt-3
    SailorSQCStatementTableChanges(v-if="checkAccess('backOffice')" :sailorDocument="statement")
    label(:class="{ 'ml-3': checkAccess('backOffice') }") {{ $t('existsDocs') }}:
  TableV2(
    v-if="statement?.status_dkk?.exists_docs.length"
    :headers="fieldsExistDocuments"
    :items="statement.status_dkk.exists_docs"
    isHiddenTableCount
    isHiddenPageCount
    isHiddenTableSearch).col-12

  div(v-if="statement?.status_dkk?.documents.length").col-12
    label {{ $t('notExistsDocs') }}:
  TableV2(
    v-if="statement?.status_dkk?.documents.length"
    :headers="fieldsApplication"
    :items="statement.status_dkk.documents"
    isHiddenTableCount
    isHiddenPageCount
    isHiddenTableSearch).col-12

  div(v-if="statement.status_dkk").col-12
    label {{ $t('experience') }}: {{ $t('noCheck') }}
  TableV2(
    :headers="fieldsExperience"
    :items="statement.status_dkk.experince"
    isHiddenTableCount
    isHiddenPageCount
    isHiddenTableSearch).col-12

  div(v-if="statement.is_cadet").col-12
    +data-info('cadet','statement.is_cadet ? $t("yes") : $t("no")')

  div.col-12
    +data-info('number', 'statement.number')

  div.col-sm-12.col-md-6
    +data-info('rank', 'rankByID(statement.rank)[nameLang]')

  div.col-sm-12.col-md-6
    b {{ $t('position') }}:
    span(v-for="position in statement.list_positions" :key="position.id") {{ positionByID(position)[nameLang] }};

  div.col-sm-12.col-md-6(v-if="statement.ces_exam")
    b {{ $t('typeStatement') }}:
    span {{ $t('CesExam')   }}

  div.col-sm-12.col-md-6
    +data-info('dataEvent', 'getDateFormat(statement.date_meeting) || "-"')

  div.col-sm-12.col-md-6
    b {{ $t('type') }}:
    span {{ getTypeSQC({ value: 'listTypeSQC', forStatementSQC: statement.is_continue })[nameLang] }}

  div(v-if="checkAccess('backOffice')").col-sm-12.col-md-6
    b {{ $t('typeDoc') }}:
    span {{ statement.is_continue ? $t('confirmation') : $t('appropriation') }}

  div(v-if="statement.is_etransport_pay && !statement.is_payed").col-sm-12.col-md-6
    b {{ $t('etransportPayment') }}:
    router-link(href="https://sea.e-transport.gov.ua" target="_blank") (https://sea.e-transport.gov.ua)

  div(v-if="checkAccess('document-author-view') && statement.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'statement.created_by.name')
  div(v-if="checkAccess('document-author-view') && statement.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'statement.created_by.date')
  div(v-if="checkAccess('verification-author-view') && statement.verificated_by").col-sm-12.col-md-6
    +data-info('verifier', 'statement.verificated_by.name')
  div(v-if="checkAccess('verification-author-view') && statement.verificated_by").col-sm-12.col-md-6
    +data-info('verificationDate', 'statement.verificated_by.date')

  div.col-12
    +data-info('payment', 'statement.is_payed ? $t("isPayed") : $t("notPayed")')

  div(v-if="statement.status_dkk").col-12
    b {{ $t('solution') }}:
    span {{ statement.status_dkk.have_all_docs ? $t('allowedConsideration') : $t('missingDocuments') }}
  div.col-12
    +data-info('status', 'statusById(statement.status_document)[nameLang]')(:class="getStatus(statement.status_document)")

  div(v-if="statement.comments?.length").col-sm-12
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    v-divider
    div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
      div
        +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== statement.comments.length-1")

  div.col-12.mt-3
    v-btn(v-if="statement.created_by_crewing_manager && statement.can_start_exam" :loading="isLoadingCopy" color="primary" @click="copyLinkOnExam").mr-3 Копіювати посилання на іспит
    v-btn(
      @click="saveDocument"
      :loading="isDocumentSavingLoading"
      color="primary").mr-3 {{ $t('saveDoc') }}
    v-btn(
      v-if="(checkAccess('verifierBackOfficeFirst') || checkAccess('verifierBackOfficeSecond')) && statement.is_continue === 1"
      @click="setTypeToExperience"
      color="primary") {{ $t('setTypeToExperience') }}
    v-btn(
      v-if="(checkAccess('verifierBackOfficeFirst') || checkAccess('verifierBackOfficeSecond')) && statement.is_continue === 3"
      @click="setTypeToExam"
      color="primary") {{ $t('setTypeToExam') }}
  v-dialog(v-model="dialog" width="500")
    v-card
      v-card-title {{$t('Verification a sailor email')}}
      v-card-text
        +field-validation('contact.value', 'email', '["email", "required"]')
      v-card-actions
        v-btn(color="success" @click="setContact('email')" :loading="isLoadingEmail") {{$t('save')}}
</template>

<script>
import { getStatus, getExperienceStatus, getDateFormat, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
import { contacts, SUCCESS_CODE } from '@/configs/constants'
import { ValidationEmail } from '@/mixins/validationRules'
export default {
  props: {
    comments: { type: Array, default: () => ([]) },
    statement: { type: Object, default: () => ({}) }
  },
  components: {
    SailorSQCStatementTableChanges: () => import('./TableChanges.vue')
  },
  data () {
    return {
      dialog: false,
      contact: {
        value: ''
      },
      isLoadingEmail: false,
      isLoadingCopy: false,
      fieldsExistDocuments: [
        { value: 'type_doc', text: this.$t('typeDoc'), sortable: false },
        { value: 'number', text: this.$t('number'), sortable: false },
        { value: 'info', text: `${this.$t('position')} / ${this.$t('nameInstitution')}`, sortable: false },
        { value: 'name_issued', text: this.$t('issued'), sortable: false },
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'date_end', text: this.$t('dateTermination'), sortable: false },
        { value: 'statusExistsDocs', text: this.$t('status'), sortable: false }
      ],
      fieldsApplication: [
        { value: 'type_document', text: this.$t('typeDoc'), sortable: false },
        { value: 'document_descr', text: this.$t('nameDoc'), tdClass: 'ellipsis', sortable: false },
        { value: 'standarts_text', text: this.$t('requirements'), tdClass: 'ellipsis', sortable: false }
      ],
      fieldsExperience: [
        { value: 'experience_descr', text: this.$t('experience'), sortable: false },
        { value: 'standarts_text', text: this.$t('standards'), sortable: false },
        { value: 'statusExperience', text: this.$t('status'), sortable: false }
      ],
      sortByA: 'status',
      sortDescA: true,
      isDocumentSavingLoading: false,
      isSwitchingTypeLoading: false,
      getExperienceStatus,
      getDateFormat,
      getStatus,
      checkAccess,
      rejectStatusId: 23
    }
  },
  validations () { return ValidationEmail() },
  computed: {
    ...mapGetters(['getTypeSQC', 'rankByID', 'positionByID', 'statusById', 'rejectionReasonByID',
      'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorInfo: state => state.sailor.sailorInfo
    }),
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.nameLang]
    }
  },
  methods: {
    ...mapActions(['getStatementDocumentSQC', 'updateSQCStatements', 'setSailorContact', 'getLinkForSailorExam']),

    async saveDocument () {
      this.isDocumentSavingLoading = true
      await this.getStatementDocumentSQC({
        body: { statement_id: this.statement.id },
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        number: this.statement.number
      })
      this.$notification.success('uploadedFile')
      this.isDocumentSavingLoading = false
    },

    setTypeToExperience () {
      this.isSwitchingTypeLoading = true
      const body = { is_continue: 3 }
      this.updateSQCStatements({ ...this.$route.params, body }).then(response => {
        if ([200, 201].includes(response?.code)) this.$notification.success('changedStatementSQC')
        else this.$notification.error('noChangedStatementSQC')
      }).finally(() => { this.isSwitchingTypeLoading = false })
    },
    setTypeToExam () {
      this.isSwitchingTypeLoading = true
      const body = { is_continue: 1 }
      this.updateSQCStatements({ ...this.$route.params, body }).then(response => {
        if ([200, 201].includes(response?.code)) this.$notification.success('changedStatementSQC')
        else this.$notification.error('noChangedStatementSQC')
      }).finally(() => { this.isSwitchingTypeLoading = false })
    },
    async copyLinkOnExam () {
      this.isLoadingCopy = true
      let checkEmail = this.sailorInfo.contact_info.find(el => el.type_contact === contacts.email.id)
      if (!checkEmail.is_actual) {
        this.contact.value = checkEmail.value
        this.dialog = true
        this.isLoadingCopy = false
      } else {
        const exam = await this.getLinkForSailorExam({ statementId: this.statement.id })
        if (exam !== '') {
          let link = exam.data.domain + '/?' + 'token=' + exam.data.token + '&examID=' + exam.data.user_exam_id
          copyToClipboard(link)
          this.isLoadingCopy = false
        }
      }
    },
    setContact (typeContact) {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoadingEmail = true
      this.$swal({
        icon: 'info',
        title: `${this.$t('Do you really want verification contact')} ?`,
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async (response) => {
        let body = {
          type_contact: contacts[typeContact].id,
          value: this.contact.value
        }
        if (response) {
          const saveContact = await this.setSailorContact({ body })
          if (SUCCESS_CODE.includes(saveContact.code)) {
            this.sailorInfo[typeContact] = null
            this.sailorInfo.contact_info = [...this.sailorInfo.contact_info, saveContact.data]
            this.$notification.success('verificationSailorContact')
            this.sailorInfo.contact_info.find(el => el.type_contact === contacts.email.id).is_actual = true
            this.copyLinkOnExam()
            this.isLoadingEmail = false
          }
        }
        this.isLoadingEmail = false
      })
    }
  }
}
</script>
